<template>
  <b-modal
    id="blacklist-form-modal"
    ref="blacklist-form-modal"
    modal-class="modal-primary"
    title="เพิ่มตัวแทนที่โดน Blacklist"
    hide-footer
    size="lg"
  >
    <validation-observer ref="blacklistForm">
      <b-form @submit.prevent="onSubmit">
        <b-row class="mb-1 mr-1">
          <b-col>
            <b-form-group
              label="ค้นหาชื่อตัวแทน"
              label-for="search"
            >
              <b-input-group class="input-group-merge">
                <b-form-input
                  id="search"
                  v-model="search"
                  type="search"
                  placeholder="ชื่อตัวแทน"
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="SearchIcon"
                    class="text-muted"
                  />
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr class="mt-0">

        <h6 class="text-secondary">
          รายชื่อตัวแทน
        </h6>
        <b-row v-if="filteredAgents.length > 0">
          <b-col
            v-for="agent in filteredAgents"
            :key="agent.id"
            cols="6"
            class="mb-1"
          >
            <div class="d-flex">
              <b-form-checkbox
                v-model="agent.isSelect"
                :name="agent.name"
              />
              <span>
                {{ agent.name }}
              </span>
            </div>
          </b-col>
        </b-row>
        <p
          v-else
          class="text-center text-muted"
        >
          ไม่พบตัวแทน หรือตัวแทนมีรายชื่อที่ไม่ได้ใช้บริการอยู่แล้ว
          <br>
        </p><div class="d-flex justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-1"
            @click="hideModal"
          >
            ปิด
          </b-button>
          <b-overlay :show="isLoading">
            <b-button
              variant="secondary"
              type="submit"
            >
              เพิ่ม {{ selectedBlacklist.length > 0?`(${selectedBlacklist.length })`: '' }}
            </b-button>
          </b-overlay>
        </div>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'BlacklistFormModal',
  props: {
    gameId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      search: '',

        filteredAgents: [],
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.game.isAddingBlacklist,
      isSuccess: (state) => state.game.isAddBlacklistSuccess,
    }),
    ...mapGetters(['agents']),
    selectedBlacklist() {
        return this.filteredAgents.filter((agent) => agent.isSelect)
    },
  },
  watch: {
    isSuccess(val) {
      if (val) {
        this.$emit('refetch')
      }
    },
    agents(val) {
        if(val.length > 0) {
            this.setData()
        }
    },
  },
  created() {
      this.setData()
  },
  methods: {
    ...mapActions(['addBlacklist']),
    setData() {
        if(this.filteredAgents.length === 0 && this.agents.length > 0){
             this.filteredAgents = this.agents.map((agent) => {
                return {
                    ...agent,
                    isSelect: false,
                }
            })
        }
    },
    hideModal() {
      this.$bvModal.hide('blacklist-form-modal')
    },
    onSubmit() {
      this.$refs.blacklistForm.validate().then(async (success) => {
        if (success) {
        //   this.addBlacklist(this.formData)
          this.hideModal()
        }
      })
    },
  },
}
</script>
